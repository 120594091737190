/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
(function ($) {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function () {
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        }
    });

    function TeaserContainer() {
        this.container = $(".teaser");
        this.backupContainer = $(".teaser-backup");
        this.filter = function (filterClass) {
            var count = 0;
            var content = "";
            var $teaser = this.backupContainer.find(".teaser-content." + filterClass);
            $teaser.each(function () {
                if (count % 2 === 0) {
                    content += '<div class="row">';
                }
                content += $(this).wrap('<p/>').parent().html();
                if (count % 2 === 1 || count === $teaser.length - 1) {
                    content += '</div>';
                }
                count++;
            });
            this.container.html(content);
        };
    }

    var Breakpoints = {
        isXxs: function () {
            return $('.breakpoint-xxs-js').is(':visible');
        },
        isXs: function () {
            return $('.breakpoint-xs-js').is(':visible');
        },
        isSm: function () {
            return $('.breakpoint-sm-js').is(':visible');
        },
        isMd: function () {
            return $('.breakpoint-md-js').is(':visible');
        },
        isMd_lg: function () {
            return $('.breakpoint-md-lg-js').is(':visible');
        },
        isLg: function () {
            return $('.breakpoint-lg-js').is(':visible');
        },
        isXl: function () {
            return $('.breakpoint-xl-js').is(':visible');
        }
    };

    function resposiveSlideshowImages() {
        var windowSize = "";

        if (Breakpoints.isXxs()) {
            windowSize = "xxs";
        } else if (Breakpoints.isXs()) {
            windowSize = "xs";
        } else if (Breakpoints.isSm()) {
            windowSize = "sm";
        } else if (Breakpoints.isMd()) {
            windowSize = "md";
        } else if (Breakpoints.isMd_lg()) {
            windowSize = "md-lg";
        } else if (Breakpoints.isLg()) {
            windowSize = "lg";
        } else if (Breakpoints.isXl()) {
            windowSize = "xl";
        }

        $('.slideshow__image-js').each(function () {
            var srcSet = $(this).attr('data-srcset');
            var sources = srcSet.split(",");
            var result = "";
            sources.forEach(function (entry) {
                var sourceArray = entry.split(" ");

                if (windowSize === sourceArray[1] || windowSize === 'md' && sourceArray[1] === 'sm') {
                    result = sourceArray[0];
                }
            });

            $(this).attr('src', result);
        });
    }

    function resizeBlogOverviewElements($elements, elementsPerRow) {
        if ($elements.length) {
            $elements.each(function () {
                this.style.height = "";
            });
            if (elementsPerRow > 1) {
                var maxHeights = {};
                var maxHeight = 0;
                var rowCount = 0;
                $elements.each(function (index) {
                    maxHeight = Math.max(maxHeight, this.clientHeight);
                    if ((index % elementsPerRow) === (elementsPerRow - 1) || index === ($elements.length - 1)) {
                        maxHeights[rowCount] = maxHeight;
                        maxHeight = 0;
                        rowCount++;
                    }
                });
                rowCount = 0;
                $elements.each(function (index) {
                    if (!this.style.height) {
                        this.style.height = maxHeights[rowCount] + "px";
                    }
                    if ((index % elementsPerRow) === (elementsPerRow - 1) || index === ($elements.length - 1)) {
                        rowCount++;
                    }
                });
            }
        }
    }

    function resizeBlogOverview($element) {
        var elementsPerRow = 1;
        if ($element.hasClass('post-overview--sidebar')) {
            if (Breakpoints.isXs() || Breakpoints.isSm()) {
                elementsPerRow = 2;
            }
        } else {
            elementsPerRow = 3;
            if (Breakpoints.isXxs()) {
                elementsPerRow = 1;
            } else if (Breakpoints.isXs() || Breakpoints.isSm()) {
                elementsPerRow = 2;
            }
        }

        $('.post-overview').imagesLoaded(function () {
            resizeBlogOverviewElements($element.find(".post-overview__text-container--js"), elementsPerRow);
            resizeBlogOverviewElements($element.find(".post-overview__image-container--js"), elementsPerRow);
        });
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                $(".icon-chevron-down, .icon-chevron-up").on("click", function () {
                    $(this).next(".dropdown-menu").slideToggle();
                    if ($(this).hasClass("icon-chevron-down")) {
                        $(this).removeClass("icon-chevron-down").addClass("icon-chevron-up");
                    } else {
                        $(this).removeClass("icon-chevron-up").addClass("icon-chevron-down");
                    }
                });

                var $slideshow = $('.slideshow-js');
                if ($slideshow.length) {
                    resposiveSlideshowImages();
                    $slideshow.imagesLoaded(function () {
                        $slideshow.show();
                        $slideshow.bxSlider({
                            auto: true,
                            controls: false,
                            mode: 'horizontal',
                            pager: false,
                            speed: 1000,
                            preloadImages: 'all',
                            pause: 5000,
                            nextText: '',
                            prevText: ''
                        });
                    });
                    $(window).resize(function () {
                        resposiveSlideshowImages();
                    });
                }

                var lightboxes = document.getElementsByClassName('lightbox-js');
                for (var i = 0; i < lightboxes.length; i++) {
                    lightGallery(lightboxes[i], {
                        selector: ".lightbox-element-js"
                    });
                }

                var $postOverview = $('.post-overview--js');
                if ($postOverview.length) {
                    resizeBlogOverview($postOverview);
                    $(window).resize(function () {
                        resizeBlogOverview($postOverview);
                    });
                }

                var $infowindow = $('.infowindow');
                if ($infowindow.length && !sessionStorage.getItem('zaeuneInfoSeen')) {
                    var $body = $('body');
                    $infowindow.removeClass('infowindow--hidden');
                    $body.addClass('page--infowindow-open');

                    var closeCallback = function () {
                      $infowindow.addClass('infowindow--hidden');
                      $body.removeClass('page--infowindow-open');
                      sessionStorage.setItem('zaeuneInfoSeen', true);
                    };

                    $('.infowindow__overlay--js').on('click', closeCallback);
                    $('.infowindow__close--js').on('click', closeCallback);
                }

                $('.video--js').on('click', function () {
                    if ($(this).get(0).playing) {
                        $(this).trigger('pause').parent().removeClass('playing--js');
                    } else {
                        $('.video--js').trigger('pause').parent().removeClass('playing--js');
                        $(this).trigger('play').attr('controls', 'controls').parent().addClass('playing--js');
                    }
                });

                $('.video--js').hover(function () {
                    if (!this.hasAttribute('controls') && $(this).get(0).playing) {
                        this.setAttribute('controls', 'controls');
                    }
                }, function () {
                    this.removeAttribute('controls');
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // On every detail page
        'page_template_detail': {
            init: function () {
                var teaserContainer = new TeaserContainer();
                $(".teaser-category").on("click", function () {
                    $(this).addClass("active");
                    $(this).siblings().removeClass("active");
                    teaserContainer.filter($(this).attr("data-filter"));
                });
            }
        },
        'partner': {
            init: function () {
                $(".teaser-content img").each(function () {
                    var $parent = $(this).parent();
                    if ($parent.prop("tagName").toLowerCase() === "a") {
                        $parent.addClass("img-link");
                    }
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
